import $ from "jquery";
import 'bootstrap';
import { Tooltip } from "bootstrap";
import "@hotwired/turbo-rails";
import "../controllers";

$(document).on(
  "turbo:load",
  () => new Tooltip("body", { selector: '[data-bs-toggle="tooltip"]', trigger: "hover" }),
);
